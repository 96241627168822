import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
// Assets
import Group from "../../assets/img/Group-89408.svg";
import development from "../../assets/img/cdd.png";
import Testing from "../../assets/img/testing.png";
import Service from "../../assets/img/service.jpeg";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightClinet" style={{ padding: "50px 0" }}>
        <div className="container" >
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            <p className="font13" style={{marginLeft:"9px"}}>
            Rapportsoft Consulting and Technology Pvt. Ltd. is one of the India's largest Shipping software products company providing innovative and integrated enterprise solutions ensuring customer satisfaction. Any organization needs a strong solid foundation of their business process implementations for it to sustain in the industry and increase ROI. With the ever-growing technologies and available design, architectural and implementation solutions, businesses are finding it difficult to evaluate the right choice for their business needs. The right choice is the one that not only makes the design, development and deployment in the most effective way but also ensures application enhancements and maintenance that could be done with minimum effort. Rapportsoft Suite of Products are built on JAVA / J2EE platform. Rapportsoft services for the products built on advanced technologies include the following –
              <br />
             
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
              image={Group}
                icon="roller"
                title="Software Development"
                subtitle="Software development services are aimed at maximizing the ROI of a software development initiative through effective planning, execution and management of new software development project, or setting to rights an ongoing project. Providing all-round software consulting, Rapportsoft helps clients from 14 industries keep end-to-end software development fast and economically sound and deliver high-quality software solutions."
              />
            </ServiceBoxWrapper>
            {/* <ServiceBoxWrapper>
              <ServiceBox
                image={Custom}
                title="Custom Software Development"
                subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore."
              />
            </ServiceBoxWrapper> */}
              <ServiceBoxWrapper>
              <ServiceBox
               
                image={Service}
                
                title="Application Services"
                subtitle="The full set of services around development and maintenance of complex business-critical applications. Our experts build, test, deploy, protect, manage, migrate and optimize enterprise-scale digital solutions ensuring they’re always up and running and achieve the optimal TCO.

                "
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
              image={development}
                icon="browser"
                title="Software Product Development"
                subtitle="With software product development outsourced, you free up your resources and get access to the tech expertise of a third-party vendor that handles the development and evolution of your app. Relying on 11-year experience in IT, Rapportsoft offers all-round self-managed outsourced product development services that help you combine fast product evolution and stability."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
               image={Testing}
              title="Testing & QA" subtitle="We offer full-range QA and testing outsourcing services, can help to develop your QA or enhance the existing one and evolution. We perform end-to-end testing of mobile, web and desktop application at each stage of the development lifecycle.

              " />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
          {/* <ServiceBoxRow className="flex">
          
            <ServiceBoxWrapper>
              <ServiceBox
                image={consulting}
                title="IT Consulting"
                subtitle="Our experts can help to develop and implement an effective IT strategy, assist in smooth digital transformation and system integration as well as advise on improvements to your digital customer experience."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
               image={images}
                title="Data Analytics"
                subtitle="We support businesses in achieving fact-based decision-making by converting their historical and real-time, traditional and big data into actionable insights. Our services are tailored to make the raw data and the environment ready, as well as strengthen the business with advanced analytics capabilities."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
                image={Infra}
              title="Infrastructure Services" subtitle="We apply our 10-year experience to offer a full set of infrastructure services. We can help to maintain and modernize your IT infrastructure and solve various infrastructure-specific issues a business may face.

              " />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
             image={HelpDesk}
                title="Help Desk Services"
                subtitle="Help desk services for your IT environment or software products. We take on solving diverse issues from answering application functionality questions to performing fixes and enhancements on the code level for improved adoption of software, its smooth functioning and increased end user satisfaction."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow> */}

        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 25%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
// const AddImgWrapp1 = styled.div`
//   width: 48%;
//   margin: 0 6% 10px 6%;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;
// const AddImgWrapp2 = styled.div`
//   width: 30%;
//   margin: 0 5% 10px 5%;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;
// const AddImgWrapp3 = styled.div`
//   width: 20%;
//   margin-left: 40%;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;
// const AddImgWrapp4 = styled.div`
//   width: 30%;
//   margin: 0 5%auto;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;




//import styled from 'styled-components';

const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    object-fit: cover; /* Ensures the image covers the entire container */
  }
`;

const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    object-fit: cover; /* Ensures the image covers the entire container */
  }
`;

const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    object-fit: cover; /* Ensures the image covers the entire container */
  }
`;

const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5% auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    object-fit: cover; /* Ensures the image covers the entire container */
  }
`;



import React, { useEffect, useState, useContext } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}

  from 'mdb-react-ui-kit';
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import 'react-toastify/dist/ReactToastify.css';
import container1 from "../../assets/img/pxfuel.jpg"
//import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import ipaddress from "../ipaddress";
import confetti from 'canvas-confetti';
// import "../Style.css";
// import "../login.css";
//import '../Style.css';
import rapport from "../../assets/img/image copy.png";
// import AuthContext from "./AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { faHome, faKey, faRedoAlt, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';
// import CFSService from "../service/CFSService";
import ButtonGroup from 'react';
import AuthContext from "../AuthProvider";
import Landing from "../../screens/Landing";
import Swal from "sweetalert2";

import RapportSoftlogo from "../../assets/img/image copy.png";
export default function Register() {

  const { login } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  // const [LogInErrors, setLogInErrors] = useState({});
  const [LogInErrors, setLogInErrors] = useState({
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});

  const [user, setuser] = useState({
    firstName: "",
    user_Email: "",
    subject: "",
    message: "",
    companyName: "",
    address: "",
    pinCode: "",
    city: "",
    state: "",
    gstNo: "",
    panNo: "",
    tanNo: "",
    mobile: "",
    user_Name: "",
    personDesignation: "",
    user_Password: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setuser({
      ...user,
      [name]: value,
    });
    // Remove the error for the specific input
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',  // Clears the error for the specific field
    }));
  };
  const validateForm = () => {
    const newErrors = {};
    if (!user.companyName) newErrors.companyName = 'Company Name is required';
    if (!user.user_Password) newErrors.user_Password = 'Password is required';
    if (!user.address) newErrors.address = 'Address is required';
    if (!user.pinCode) newErrors.pinCode = 'Pin Code is required';
    if (!user.city) newErrors.city = 'City is required';
    if (!user.state) newErrors.state = 'State is required';
    if (!user.gstNo) newErrors.gstNo = 'GST No is required';
    if (!user.panNo) newErrors.panNo = 'PAN No is required';
    if (!user.mobile) newErrors.mobile = 'Contact No is required';
    if (!user.user_Name) newErrors.user_Name = 'Person Name is required';
    if (!user.user_Email
    ) newErrors.user_Email = 'Email is required';
    if (!user.file) newErrors.file = 'File Upload size is under 10 mb required';
    return newErrors;
  };

  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);

  const getStates = () => {
    axios.get(`${ipaddress}jar/getData?jarid=ST`)
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {

      })
  }

  const getCity = () => {
    axios.get(`${ipaddress}jar/getData?jarid=CTY`)
      .then((response) => {
        setCity(response.data);
      })
      .catch((error) => {

      })
  }
  useEffect(() => {
    getCity();
    getStates();
  }, []);

  // const handleImageChange = (e) => {
  //   const imagePath = e.target.files[0];

  //   const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

  //   // Reset errors
  //   setErrors((prevErrors) => ({ ...prevErrors, file: null }));

  //   if (imagePath) {
  //     // Validate if the selected file is an image
  //     const validFileTypes = ['application/pdf'];
  //     if (!validFileTypes.some((type) => imagePath.type.startsWith(type))) {
  //       alert('Please select an PDF file.');
  //       return;
  //     }
  //     // Read the file and set it as the image path
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       setuser((prevEmployee) => ({
  //         ...prevEmployee,
  //         file: event.target.result
  //       }));
  //     };
  //     reader.readAsDataURL(imagePath);
  //   }
  // };

  //   const handleImageChange = (e) => {
  //     const selectedFile = e.target.files[0];
  //     const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

  //     // Reset errors
  //     setErrors((prevErrors) => ({ ...prevErrors, file: null }));

  //     if (selectedFile) {
  //       if (selectedFile.type !== "application/pdf") {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           file: "Please upload a valid PDF file.",
  //         }));
  //       } else if (selectedFile.size > maxSize) {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           file: "File size must not exceed 10 MB.",
  //         }));
  //       } else {
  //         // Clear errors and set file
  //         setuser((prev)=>({
  // ...prev,
  // file:selectedFile,
  //         }));

  //         console.log("File uploaded successfully:", selectedFile);
  //       }
  //     }
  //   };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file

    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
    const validFileTypes = ['application/pdf']; // Only allow PDF files

    // Reset errors
    setErrors((prevErrors) => ({ ...prevErrors, file: null }));

    if (file) {
      // Check if the file type is valid
      if (!validFileTypes.some((type) => file.type === type)) {
        alert('Please select a PDF file.');
        return;
      }

      // Check if the file size exceeds the maximum limit
      if (file.size > maxSize) {
        alert('File size exceeds the 10 MB limit. Please upload a smaller file.');
        return;
      }

      // Read the file and set it in the user state
      const reader = new FileReader();
      reader.onload = (event) => {
        setuser((prevUser) => ({
          ...prevUser,
          file: event.target.result, // Store the file data
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const validateGST = (value) => {
    // GSTIN format validation regex
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

    if (!value.match(gstRegex)) {
      return "Enter a valid GST number";
    }
    return "";
  };

  const validatePAN = (value) => {
    const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
    if (!value.match(panPattern)) {
      return "Enter a valid PAN number";
    }
    return "";
  };

  // const validateMobile = (value) => {
  //   const mobilePattern = /^[6-9]\d{9}$/; // Validates Indian mobile numbers starting with 6-9 and having 10 digits
  //   if (!value.match(mobilePattern)) {
  //     return "Enter a valid mobile number";
  //   }
  //   return "";
  // };
  const validateMobile = (value) => {
    const mobilePattern = /^[6-9]\d{9,11}$/; // Validates mobile numbers starting with 6-9 and having 10 or 12 digits

    if (!value.match(mobilePattern)) {
      return "Enter a valid mobile number (10 digits)";
    }
    return "";
  };

  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value.match(emailPattern)) {
      return "Enter a valid email address";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading1(true);

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setLoading1(false);
      setErrors(errors);
      return;
    }

    // const fileInput = document.getElementById('mypdffile'); // Ensure the file input has this ID
    // if (fileInput && fileInput.files[0]) {
    //   const file = fileInput.files[0];
    //   const maxSizeInBytes = 10 * 1024 * 1024; // 1 MB

    //   if (file.size > maxSizeInBytes) {
    //     setErrors({ ...errors, file: 'File size exceeds 10 MB limit' });
    //     toast.error('File size exceeds the 10 MB limit.', {
    //       position: 'top-center',
    //       autoClose: 900,
    //     });
    //     return;
    //   }
    // }
    const panError = validatePAN(user.panNo);
    const gstError = validateGST(user.gstNo);
    //const mobileError = validateMobile(user.mobile);
    const emailError = validateEmail(user.user_Email);

    if (panError || gstError || emailError) {
      setErrors({
        ...errors,
        panNo: panError,
        gstNo: gstError,
        user_Email: emailError,
      });
      toast.error('Please fill in all required fields and ensure GST, PAN, Mobile, and Email numbers are valid.', {
        position: 'top-center',
        autoClose: 900,
      });
      setLoading1(false);
      return;
    }
    console.log("user", user);

    axios.post(`${ipaddress}user/saveUser`, user)
      .then((response) => {
        setLoading1(false);
        const result = response.data;
        toast.success('Registration Successful! Congratulations! You have registered successfully.', {
          position: 'top-center',
          autoClose: 900,
        });
        setuser('');
        setErrors('');
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
        navigate('/login');

      })
      .catch((error) => {
        setLoading1(false);
        console.log("error",);
        Swal.fire({
          icon: 'error',
          title: 'Registration Failed',
          text: error.response.data,
          customClass: {
            popup: 'swal2-popup',
            title: 'swal2-title',
            content: 'swal2-content',
            confirmButton: 'swal2-confirm',
            cancelButton: 'swal2-cancel'
          }
        });

      }
      )
  };

  const navigate = useNavigate();

  const Footer = () => {
    return (
      <footer className="footer mt-auto py-3">
        <div className="container text-center text-white" style={{ background: 'linear-gradient(to left, #ee7724, #d8363a, #dd3675, #b44593)' }}>
          {/* <span>© {new Date().getFullYear()} Rapportsoft Consulting and Technology Pvt. Ltd. All rights reserved.</span> */}
          <span style={{ color: 'white' }}>
            © {new Date().getFullYear()}-
            <a href="https://rapportsoft.co.in" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
              Rapportsoft Consulting and Technology Pvt. Ltd
            </a>
            . All rights reserved.
          </span>
        </div>
      </footer>
    );
  };


  const [loading1, setLoading1] = useState(false);


  return (
    <>
      {loading1 && (
        <>
          <div className="spinner-overlay">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              style={{ width: 50, height: 50 }}
              variant="danger"
              role="status"
              aria-hidden="true"
            />
          </div>

        </>
      )}
      <MDBContainer className="my-5 gradient-form">
        <MDBRow>
          <MDBCol col='6' className="mb-5" style={{ marginRight: 36 }}>
            <div className="d-flex flex-column ms-5">
              <div className="text-center" style={{ paddingBottom: 54 }}>
                <img src={RapportSoftlogo} style={{ width: '270px' }} alt="logo" />
              </div>

              <MDBInput
                wrapperClass='mb-4'
                id='form2'
                type="text"
                name="companyName"
                placeholder={errors.companyName ? errors.companyName : "Company Name *"}  // Error message as placeholder
                value={user.companyName}
                onChange={handleChange}
                maxLength={90}
                style={{
                  border: errors.companyName ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}
              />

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="text"
                name="address"
                placeholder={errors.address ? errors.address : "Address *"}
                value={user.address}
                onChange={handleChange}
                maxLength={90}
                style={{
                  border: errors.address ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}

              />

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="text"
                name="pinCode"
                placeholder={errors.pinCode ? errors.pinCode : "Pin Code *"}
                value={user.pinCode}
                maxLength={6}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*$/.test(input)) { // Allow only digits
                    handleChange(e); // Call your existing handleChange function
                  }
                }}
                style={{
                  border: errors.pinCode ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}

              />

              <select
                className={`form-select mb-4 ${errors.state ? "error-input" : ""}`}  // Add error class for select
                id="stateSelect"
                name="state"
                value={user.state}
                onChange={handleChange}
                style={{
                  border: errors.state ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}
              >
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option key={index} value={state.jarDtlId}>
                    {state.jarDtlDesc}
                  </option>
                ))}
              </select>

              <select
                className={`form-select mb-4 ${errors.city ? "error-input" : ""}`}  // Add error class for select
                id="stateSelect"
                name="city"
                value={user.city}
                onChange={handleChange}
                style={{
                  border: errors.city ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}
              >
                <option value="">Select City</option>
                {city.map((state, index) => (
                  <option key={index} value={state.jarDtlId}>
                    {state.jarDtlDesc}
                  </option>
                ))}
              </select>

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="text"
                name="gstNo"
                placeholder={errors.gstNo ? errors.gstNo : "GST No *"}
                value={user.gstNo}
                onChange={handleChange}
                style={{
                  border: errors.gstNo ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                  textTransform: 'uppercase'
                }}
                maxLength={15}
              />

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="text"
                name="panNo"
                maxLength={10}
                placeholder={errors.panNo ? errors.panNo : "PAN No *"}
                value={user.panNo}
                onChange={handleChange}
                style={{
                  border: errors.panNo ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                  textTransform: 'uppercase'
                }}

              />

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="text"
                name="tanNo"
                placeholder="TAN No"
                maxLength={10}
                value={user.tanNo}
                onChange={handleChange}
                style={{ textTransform: 'uppercase' }}
              />

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="text"
                name="mobile"
                placeholder={errors.mobile ? errors.mobile : "Contact No *"}
                value={user.mobile}
                maxLength={15}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*$/.test(input)) { // Allow only digits
                    handleChange(e); // Call your existing handleChange function
                  }
                }}
                style={{
                  border: errors.mobile ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}
              />

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="text"
                name="user_Name"
                placeholder={errors.user_Name ? errors.user_Name : "Person Name *"}
                value={user.user_Name}
                maxLength={35}
                onChange={handleChange}
                style={{
                  border: errors.user_Name ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}
              />

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="text"
                name="personDesignation"
                maxLength={30}
                placeholder="Person Designation"
                value={user.personDesignation}
                onChange={handleChange}
              />

              <MDBInput
                wrapperClass="mb-4"
                id="form2"
                type="email"
                name="user_Email"
                maxLength={50}
                placeholder={errors.user_Email ? errors.user_Email : "Email *"}
                value={user.user_Email}
                onChange={handleChange}
                style={{
                  border: errors.user_Email ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}

              />

              <MDBInput
                //                wrapperClass="mb-1"
                id="form2"
                type="file"
                name="file"
                placeholder="Upload File *"
                accept="application/pdf"  // Restrict to PDF
                onChange={handleImageChange}
                className="custom-file-input"
                style={{
                  border: errors.file ? '2px solid red' : '',  // Red border for error
                }}
              />
              <span style={{ color: 'red', fontSize: 14 }}>Please attach a company-related PDF document with a maximum size of 10 MB.</span>
              {/* {errors.file && <span style={{ color: 'red' }}>{errors.file}</span>} */}

              <MDBInput
                wrapperClass="mb-4 mt-2"
                id="mypdffile"
                type="password"
                name="user_Password"
                maxLength={18}
                placeholder={errors.user_Password ? errors.user_Password : "Password *"}
                value={user.user_Password}
                onChange={handleChange}
                style={{
                  border: errors.user_Password ? '2px solid red' : '',  // Red border for error
                  position: 'relative',  // To allow positioning of the error message inside the input
                }}

              />


              {/* <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="companyName" placeholder="Company Name *" value={user.companyName} onChange={handleChange} />
            {errors.companyName && <span style={{ color: 'red' }}>{errors.companyName}</span>} */}

              {/* <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="address" placeholder="Address *" value={user.address} onChange={handleChange} />
            {errors.address && <span style={{ color: 'red' }}>{errors.address}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="pinCode" placeholder="Pin Code *" value={user.pinCode} onChange={handleChange} />
            {errors.pinCode && <span style={{ color: 'red' }}>{errors.pinCode}</span>} 
            <select
  className="form-select mb-4"
  id="stateSelect"
  name="state"
  value={user.state}
  onChange={handleChange}
>
  <option value="">Select State</option>
  {states.map((state,index) => (
    <option key={index} value={state.jarDtlId}>
      {state.jarDtlDesc}
    </option>
  ))}
</select>

{errors.state && <span style={{ color: 'red' }}>{errors.state}</span>}


<select
  className="form-select mb-4"
  id="stateSelect"
  name="city"
  value={user.city}
  onChange={handleChange}
>
  <option value="">Select City</option>
  {city.map((state,index) => (
    <option key={index} value={state.jarDtlId}>
      {state.jarDtlDesc}
    </option>
  ))}
</select>
            {errors.city && <span style={{ color: 'red' }}>{errors.city}</span>} */}


              {/* 
            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="gstNo" placeholder="GST No *" value={user.gstNo} onChange={handleChange} />
            {errors.gstNo && <span style={{ color: 'red' }}>{errors.gstNo}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="panNo" placeholder="PAN No *" value={user.panNo} onChange={handleChange} />
            {errors.panNo && <span style={{ color: 'red' }}>{errors.panNo}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="tanNo" placeholder="TAN No" value={user.tanNo} onChange={handleChange} />

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="mobile" placeholder="Contact No *" value={user.mobile} onChange={handleChange} />
            {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="user_Name" placeholder="Person Name *" value={user.user_Name} onChange={handleChange} />
            {errors.user_Name && <span style={{ color: 'red' }}>{errors.user_Name}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="personDesignation" placeholder="Person Designation" value={user.personDesignation} onChange={handleChange} />

            <MDBInput wrapperClass='mb-4'  id='form2' type="email" name="user_Email" placeholder="Email *" value={user.user_Email} onChange={handleChange} />
            {errors.user_Email && <span style={{ color: 'red' }}>{errors.user_Email}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="file" name="file" placeholder="Upload File *" accept="image/*,application/pdf" onChange={handleImageChange} className="custom-file-input" />
            {errors.file && <span style={{ color: 'red' }}>{errors.file}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="password" name="user_Password" placeholder="Password *" value={user.user_Password} onChange={handleChange} />
            {errors.user_Password && <span style={{ color: 'red' }}>{errors.user_Password}</span>} */}

              <div className="text-center pt-1 mb-2">
                <Button outline className="mb-4 w-100 gradient-custom-2" onClick={handleSubmit}>
                  Sign Up
                </Button>

                <div className="d-flex flex-row align-items-center justify-content-center pb-1 mb-1">
                  <p className="mb-0" style={{ marginLeft: 9 }}>already have an account?</p>
                  <div >&nbsp;
                    <Link to="/login">log In
                    </Link>
                  </div></div>
                <Link to="/">
                  <FontAwesomeIcon icon={faHome} style={{ color: 'red', marginRight: 4 }} />Back To Home
                </Link>

              </div>
            </div>
          </MDBCol>

          <MDBCol col='6' className="mb-5" style={{ marginLeft: 27 }}>
            <div className="d-flex flex-column justify-content-center gradient-custom-2 h-100 mb-4">
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <h4 className="mb-4">We are more than just a company</h4>
                <p className="small mb-0" style={{ marginLeft: "9px" }}>
                  At Rapportsoft Counsulting And Technology Pvt. Ltd, we transcend borders and elevate standards in the shipping and air cargo domains. Our commitment to excellence drives us to deliver unparalleled logistics solutions worldwide. With a focus on innovation and reliability, we streamline supply chains, ensuring swift and secure transport of goods across continents.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <Footer />
      </MDBContainer>
    </>
  );
}
